<template>
  <div class="login-page flex_column">
    <div class="header">
      <img class="logo-black-text" src="@/assets/logo/logo-row-text.png">
      <span class="header_desc">励志冷链，智慧物联</span>
    </div>
    <div class="flex_1 login_center_content">
      <div
        class="content flex_1"
        :style="contentStyle"
      >
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          label-position="left"
          label-width="0px"
        >
          <h3 class="title">
            仓储作业中心登录
          </h3>
          <el-form-item prop="account">
            <el-input
              v-model.trim="loginForm.userName"
              type="text"
              auto-complete="off"
              placeholder="请输入账号"
              @keyup.enter="handleLogin"
            >
              <template #prefix>
                <i class="iconfont iconuser input-icon" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" class="mt25">
            <el-input
              v-model.trim="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="密码"
              @keyup.enter="handleLogin"
            >
              <template #prefix>
                <i class="iconfont iconlock input-icon" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <nh-button
              :loading="loading.handleLogin"
              class="login-btn"
              type="primary"
              :disabled="!loginForm.password || !loginForm.userName"
              @click="handleLogin"
            >
              登录
            </nh-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      成都运荔枝科技有限公司提供技术支持
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import { configCenter } from './api';
import defaultBgImg from './images/login-bg.png';
import snowImg from './images/snow.png';

export default {
  name: 'Login',
  mixins: [loadingMixin],
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value || value.length < 5) {
        callback(new Error('密码不能小于5位'));
      } else {
        callback();
      }
    };
    return {
      loading: {
        handleLogin: false,
      },
      contentStyle: {},
      defaultStyle: {
        backgroundColor: '#06b880',
        backgroundImage: `url(${defaultBgImg}), url(${snowImg})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: 'auto 500px, auto 55px',
        backgroundPosition: '30% 0, 20% 0',
      },
      loginForm: {
        userName: process.env.VUE_APP_LOGIN_ACCOUNT || '',
        password: process.env.VUE_APP_LOGIN_PASSWORD || '',
      },
      loginRules: {
        userName: [{ required: true }],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
      },
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getBgImg();
  },
  methods: {
    async getBgImg() {
      let style = this.defaultStyle;
      try {
        const [resp] = await configCenter({
          configKey: 'web.wms2.0.login.bgImg',
        }) || [];
        const { deadline, imgUrl, backgroundColor } = JSON.parse(resp.configValue);
        if (moment().isSameOrBefore(deadline, 'day')) {
          style = {
            backgroundColor,
            backgroundImage: `url(${imgUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: '0 0',
          };
        }
      } finally {
        this.contentStyle = { ...style };
      }
    },
    async handleLogin() {
      await this.$refs.loginForm.validate();
      await this.$store.dispatch('user/login', this.loginForm);
      this.$router.replace({ path: this.redirect || '/' });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);

  .logo-black-text {
    height: 55px;
  }

  .header_desc {
    font-size: 24px;
    font-weight: 400;
    color: #606266;
  }
}

.login_center_content {
  display: flex;
  align-items: center;
}

.content {
  padding: 60px 0;
}

.login-form {
  margin-left: 55%;
  width: 400px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  padding: 72px 40px 50px;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #171717;
    margin-bottom: 40px;
  }

  .login-btn {
    width: 100%;
    margin-top: 25px;
  }

  .input-icon {
    margin-left: 3px;
    font-size: 17px;
  }
}

.footer {
  text-align: center;
  padding: 40px 0;
  color: #515151;
  font-size: 16px;
}
</style>
